import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import 'survey-core/defaultV2.min.css';
import { Model } from 'survey-core';
import { Survey } from 'survey-react-ui';
import { Player } from '@lottiefiles/react-lottie-player';
import loadingAnimation from "./Animation.json";
import { ComponentCollection } from "survey-core";
import './index.css'
import { surveyLocalization } from "survey-core";
import "survey-core/survey.i18n";



ComponentCollection.Instance.add({
    name: "nps",
    title: "NPS Question",
    questionJSON: {
        type: "rating",
    rateCount: 11,
    rateMin: 0,
    rateMax: 10,
    minRateDescription: "Highly Unlikely",
    maxRateDescription: "Highly Likely",
    cssClass: "custom-nps-question",
        placeholder: "Select a country...",
        choicesByUrl: {
            url: "https://surveyjs.io/api/CountriesExample",
        },
    },
	
});
ComponentCollection.Instance.add({
    name: "csat",
    title: "CSAT Question",
    questionJSON: {
        type: "rating",
    rateType: "smileys", // or "stars"
		rateCount: 5,
    cssClass: "custom-nps-question",
          rateMax: 5,
        choicesByUrl: {
            url: "https://surveyjs.io/api/CountriesExample",
        },
    },
});


function App() {

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const uuid = queryParams.get('uuid');
  const channel = queryParams.get('channel');
  const domainId = queryParams.get('domain_id');
  const contactid = queryParams.get('contact_form');
  const surv = queryParams.get('survey_forms');
  const uuidd = queryParams.get('uuidd');
  const mrnumber = queryParams.get('mrnumber');  
  console.log("contactid",surv)

  var fetchUrl = `${process.env.REACT_APP_API_URL}.get_survey_data?uuid=${uuid}&channel=${channel}&domain_id=${domainId}`;

  const [surveyJson, setSurveyJson] = useState(null);
  const [themeJson, setThemeJson] = useState(null);
  const [survey, setSurvey] = useState(null);
  const [alreadySubmitted, setAlreadySubmitted] = useState(false);


  if (uuid){}

  useEffect(() => {
    if (uuid){
      async function fetchSurveyData() {
        try {
          const response = await fetch(fetchUrl, {
            method: 'POST',
          });
       
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
  
          const data = await response.json();
          if(data.message.status === 'Already'){
            console.log("Already submitted");
            setAlreadySubmitted(true);
            return; // Stop further execution if already submitted
          }
  
          // If data.message.status is not 'Already', continue fetching survey data
          if (data.message.survey_data) {
            setSurveyJson(data.message.survey_data);
            console.log("Survey data:", data.message.survey_data);
            if (data.message.theme_json !== null) {
              setThemeJson(JSON.parse(data.message.theme_json));
            }
          } else {
            throw new Error('Error fetching survey data');
          }
        } catch (error) {
          console.error('Network error:', error);
        }
      }
  
      fetchSurveyData();
    }
    if(contactid){
      // Override individual translations in an existing locale:
const engLocale = surveyLocalization.locales["en"];
// engLocale.pagePrevText = "Back";
// engLocale.pageNextText = "Forward";
engLocale.completeText = "Proceed";

// ... or add a custom locale
const customLocaleStrings = {
    // pagePrevText: "Back",
    // pageNextText: "Forward",
    completeText: "Proceed"
};
surveyLocalization.locales["my"] = customLocaleStrings;
      async function fetchSurveyData() {
        try {
          const response = await fetch(`${process.env.REACT_APP_API_URL}.get_contact_survey_data?contact_form=${contactid}`, {
            method: 'POST',
          });
       
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
  
          const data = await response.json();
          if(data.message.status === 'Already'){
            console.log("Already submitted");
            setAlreadySubmitted(true);
            return; // Stop further execution if already submitted
          }
  
          // If data.message.status is not 'Already', continue fetching survey data
          if (data.message.survey_data) {
            setSurveyJson(data.message.survey_data);
            console.log("Survey data:", data.message.survey_data);
            if (data.message.theme_json !== null) {
              setThemeJson(JSON.parse(data.message.theme_json));
            }
          } else {
            throw new Error('Error fetching survey data');
          }
        } catch (error) {
          console.error('Network error:', error);
        }
      }
  
      fetchSurveyData();
    }
    if(surv){
      async function fetchSurveyData() {
        try {
          const response = await fetch(`${process.env.REACT_APP_API_URL}.get_contact_survey_datas?survey_forms=${surv}`, {
            method: 'POST',
          });
       
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
  
          const data = await response.json();
          console.log(data);
          if(data.message.status === 'Already'){
           
            setAlreadySubmitted(true);
            return; // Stop further execution if already submitted
          }
  
          // If data.message.status is not 'Already', continue fetching survey data
          if (data.message.survey_data) {
            setSurveyJson(data.message.survey_data);
            console.log("Survey data:", data.message.survey_data);
            if (data.message.theme_json !== null) {
              setThemeJson(JSON.parse(data.message.theme_json));
            }
          } else {
            throw new Error('Error fetching survey data');
          }
        } catch (error) {
          console.error('Network error:', error);
        }
      }
  
      fetchSurveyData();
    }
  }
    ,[fetchUrl]);

  useEffect(() => {
    if (surveyJson) {
      const newSurvey = new Model(surveyJson);
      setSurvey(newSurvey);
    }
  }, [surveyJson]);

  useEffect(() => {
    if (survey && themeJson) {
      survey.applyTheme(themeJson);
    }
  }, [survey, themeJson]);

    var alertResults = (sender) => {
      const results = sender.data;
      const hell2 = JSON.stringify({ responses: results });
      console.log("Survey results:", results);
      const requestData = {
        uuid: uuid,
        channel:channel,
        responses: hell2,
      };
  
      fetch((`${process.env.REACT_APP_API_URL}.response_survey`), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestData),
      })
        .then(response => response.json())
        .then(data => {
          console.log("POST request response:", data);
        })
        .catch(error => {
          console.error("Error making POST request:", error);
        });
    };

    var mr_number;
    var alertResultss = (sender) => {
      const results = sender.data;
      const hell2 = JSON.stringify({ responses: results });
      console.log("Survey results:", results);
      const requestData = {
        surv:surv,
        uuidd:uuidd,
        responses: hell2,
      };
  
      fetch((`${process.env.REACT_APP_API_URL}.contact_response_survey`), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestData),
      })
        .then(response => response.json())
        .then(data => {
          console.log("POST contact form request response:", data);
          console.log(data.message.number)
           mr_number = data.message.number
           console.log(mr_number)
        })
        .catch(error => {
          console.error("Error making POST request:", error);
        });
    };
    var alertResultsss = (sender) => {
      var ur_1=window.location.href;
      var ur_2=ur_1.replace('contact_forms','contact_form').replace('survey_forms', 'survey_form');
      setTimeout(function() {
        window.location.href = ur_2;
    }, 8000);
      const results = sender.data;
      const hell2 = JSON.stringify({ responses: results });
      console.log("Survey results:", results);
      const requestData = {
        surv:surv,
        uuidd:uuidd,
        responses: hell2,
        mrnumber:mrnumber,
      };
      console.log("jhfjhjhfr",requestData)
  
      fetch((`${process.env.REACT_APP_API_URL}.contact_2_response_survey`), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestData),
      })
        .then(response => response.json())
        .then(data => {
          console.log("POST contact form request response:", data);
        })
        .catch(error => {
          console.error("Error making POST request:", error);
        });
    };


  

  if (alreadySubmitted) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          fontSize: "30px",
        }}
      >
        <h5>Feedback already submitted</h5>
        {/* You can add additional content or formatting for the already submitted state */}
      </div>
    );
  }

  if (!survey) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
             <Player
				loop
				autoplay
                    src={loadingAnimation}
                    style={{
                        width: "350px",
                        height: "auto",
                    }}
                />

      </div>
    );
  }
if (uuid){
  survey.onComplete.add(alertResults);
}
if (surv){
  survey.onComplete.add(alertResultsss);

}
if (contactid){
  survey.onComplete.add(alertResultss);
  survey.onComplete.add(function (result) {
    // Custom logic to redirect to facebook.com
    const currentURL = window.location.href;
    survey.showCompletedPage = false;

    const modifiedURL = currentURL.replace('contact_form', 'contact_forms').replace('survey_form', 'survey_forms');
    console.log("modifiedURL",modifiedURL)
    var newURL = new URL(modifiedURL);
    
       setTimeout(function() {
        newURL.searchParams.set('mrnumber', mr_number);
        console.log("newURL",newURL)
        window.location.href = newURL;
    },1000 );
    console.log("newURL",newURL)
})


}

  return <Survey model={survey} />;
}

export default App;
